var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upgrade" },
    [
      _c(
        "div",
        { staticClass: "form-wrap" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.signUpForm,
                rules: _vm.formRules,
                "label-position": "right"
              }
            },
            [
              _c(
                "div",
                { staticClass: "upgrade-info-wrap" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "inline-formitem afterSaleName",
                      staticStyle: { width: "282px" },
                      attrs: { prop: "afterSaleType" }
                    },
                    [
                      _c("span", [_vm._v("售后类型")]),
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "auto",
                          disabled: "",
                          outlined: "",
                          dense: true,
                          height: 42,
                          value: "转班"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "originOrder" } },
                        [
                          _c("span", [_vm._v("售后订单")]),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                border: "",
                                stripe: "",
                                size: "mini",
                                "element-loading-text": "拼命加载中",
                                "row-style": { height: "48px" },
                                "cell-style": { padding: "0px" },
                                "header-cell-style": { background: "#e5f2ff" },
                                "header-cell-class-name": "normal-table-header",
                                data: _vm.orderTable
                              }
                            },
                            [
                              _vm._l(_vm.orderColumns, function(item) {
                                return [
                                  item.prop === "payType"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.payTypeMap[
                                                          row.payType
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "allPay"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.allPayMap[
                                                          row.allPay
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "goodsName"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label,
                                          width: "180px",
                                          fixed: item.fixed
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(row.goodsName) +
                                                      " "
                                                  ),
                                                  row.commodityName
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            color: "#337aff",
                                                            "margin-top": "6px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.commodityName
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "goodsTypeName"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label,
                                          width: "200px"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.goodsCategoryParentName
                                                          ? row.goodsCategoryParentName +
                                                              "-" +
                                                              row.goodsCategoryName
                                                          : row.goodsCategoryName
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "goodsSeriesName"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label,
                                          width: "200px"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.goodsSeriesParentName
                                                          ? row.goodsSeriesParentName +
                                                              "-" +
                                                              row.goodsSeriesName
                                                          : row.goodsSeriesName
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "orderStatus"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.orderStatusMap[
                                                          row.orderStatus
                                                        ]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "afterSaleTypes"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.afterSaleTypes ||
                                                          "无"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "createTime"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: item.label,
                                          width: "180"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          row.createTime,
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "installmentType"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          prop: item.prop,
                                          label: item.label,
                                          width: item.width
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  row.installmentType != 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .installmentTypeMap[
                                                                row
                                                                  .installmentType
                                                              ]
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("- -")
                                                      ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "tradeSerialNumber"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          prop: item.prop,
                                          label: item.label,
                                          width: item.width
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  row.installmentType != 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              row.tradeSerialNumber
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v("- -")
                                                      ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : item.prop === "orderNo"
                                    ? _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          prop: item.prop,
                                          label: item.label,
                                          width: item.width,
                                          fixed: ""
                                        }
                                      })
                                    : _c("el-table-column", {
                                        key: item.prop,
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          prop: item.prop,
                                          label: item.label,
                                          width: item.width || "160px",
                                          fixed: item.fixed
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var row = ref.row
                                                return [
                                                  [
                                                    "productName",
                                                    "goodsSpecName",
                                                    "goodsTypeName",
                                                    "goodsSeriesName",
                                                    "signSchoolName",
                                                    "teacherRecruitName"
                                                  ].includes(item.prop)
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("empty")(
                                                              row[item.prop],
                                                              "--"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(row[item.prop])
                                                        )
                                                      ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "282px", "margin-bottom": "35px" },
                      attrs: { prop: "cashierId" }
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          label: "出纳审核",
                          items: _vm.cashierCheckList,
                          "menu-props": { bottom: true, offsetY: true },
                          "item-text": "userName",
                          "item-value": "tyUserId",
                          placeholder: "选择校区出纳",
                          outlined: "",
                          dense: true,
                          height: 42,
                          clearable: true,
                          "clear-icon": "$clear",
                          "hide-details": "auto"
                        },
                        model: {
                          value: _vm.signUpForm.cashierId,
                          callback: function($$v) {
                            _vm.$set(_vm.signUpForm, "cashierId", $$v)
                          },
                          expression: "signUpForm.cashierId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "Contextclass", attrs: { prop: "reason" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          "hide-details": "auto",
                          label: "申请原因",
                          placeholder: "售后申请说明，500字内",
                          outlined: "",
                          dense: true,
                          height: 120,
                          maxlength: "500",
                          counter: ""
                        },
                        model: {
                          value: _vm.signUpForm.reason,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.signUpForm,
                              "reason",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "signUpForm.reason"
                        }
                      })
                    ],
                    1
                  ),
                  _c("annex", { ref: "annexRef" })
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "payinfo-wrap" },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "sub-title",
                      staticStyle: { margin: "18px 0 6px 0" }
                    },
                    [_vm._v("退费信息")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dealInfo",
                      staticStyle: { "margin-top": "-12px" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "originOrder" } },
                        [
                          _c("span", [
                            _vm._v("退费计算"),
                            _c("span", { staticClass: "start" }, [_vm._v("*")])
                          ]),
                          _c(
                            "el-row",
                            _vm._l(_vm.dropOutTableList, function(
                              dropOutTable,
                              tableIndex
                            ) {
                              return _c(
                                "div",
                                {
                                  key: tableIndex,
                                  staticClass: "dropout_table",
                                  class: {
                                    single: _vm.dropOutTableList.length === 1
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "goodsAndClass",
                                      staticStyle: {
                                        background: "#f5f7fa",
                                        overflow: "hidden",
                                        padding: "10px 20px",
                                        "margin-bottom": "18px"
                                      }
                                    },
                                    [
                                      _c("el-col", { attrs: { span: 6 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { color: "#999" } },
                                          [_vm._v("商品名称")]
                                        ),
                                        _vm.classInfo[tableIndex]
                                          ? _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.classInfo[tableIndex]
                                                    .goodsName
                                                )
                                              )
                                            ])
                                          : _c("p", [_vm._v("- -")])
                                      ]),
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { color: "#999" } },
                                          [_vm._v("已收金额")]
                                        ),
                                        _vm.classInfo[tableIndex]
                                          ? _c("p", [
                                              _vm._v(
                                                "￥" +
                                                  _vm._s(
                                                    _vm._f("decimal2")(
                                                      _vm.classInfo[tableIndex]
                                                        .receivedMoney
                                                    )
                                                  )
                                              )
                                            ])
                                          : _c("p", [_vm._v("0.00")])
                                      ]),
                                      !(
                                        _vm.orderExtraInfo &&
                                        (_vm.orderExtraInfo.goodsTemplate ===
                                          _vm.goodsTemplateEnum.ckdp ||
                                          _vm.orderExtraInfo.goodsTemplate ===
                                            _vm.goodsTemplateEnum.wjdp)
                                      )
                                        ? _c("el-col", { attrs: { span: 9 } }, [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: { color: "#999" }
                                              },
                                              [_vm._v("已上课时费用")]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "classHis" },
                                              [
                                                _c("span", [
                                                  _vm._v("线上课程")
                                                ]),
                                                _vm.classInfo[tableIndex] &&
                                                _vm.classInfo[tableIndex]
                                                  .onlineViewType != 3
                                                  ? _c("span", [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .onlineViewType ==
                                                                  0 ||
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .onlineViewType ==
                                                                  2,
                                                              expression:
                                                                "\n                            classInfo[tableIndex].onlineViewType == 0 ||\n                            classInfo[tableIndex].onlineViewType == 2\n                          "
                                                            }
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.classInfo[
                                                                tableIndex
                                                              ].ineffectiveMonth
                                                            ) +
                                                              "/" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ].effectiveMonth
                                                              ) +
                                                              " 月 已上课时费用" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .onlineMonthSpendMoney
                                                              ) +
                                                              "元"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .onlineViewType ==
                                                                  1 ||
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .onlineViewType ==
                                                                  2,
                                                              expression:
                                                                "\n                            classInfo[tableIndex].onlineViewType == 1 ||\n                            classInfo[tableIndex].onlineViewType == 2\n                          "
                                                            }
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.classInfo[
                                                                tableIndex
                                                              ].signCount
                                                            ) +
                                                              "/" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ].lessonCount
                                                              ) +
                                                              " 课时 已上课时费用" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .onlineSpendMoney
                                                              ) +
                                                              "元"
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _c("span", [_vm._v("- -")])
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "classHis" },
                                              [
                                                _c("span", [
                                                  _vm._v("线下课程")
                                                ]),
                                                _vm.classInfo[tableIndex] &&
                                                _vm.classInfo[tableIndex]
                                                  .underlineViewType != 3
                                                  ? _c("span", [
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .underlineViewType ==
                                                                  0 ||
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .underlineViewType ==
                                                                  2,
                                                              expression:
                                                                "\n                            classInfo[tableIndex].underlineViewType == 0 ||\n                            classInfo[tableIndex].underlineViewType == 2\n                          "
                                                            }
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .ineffectiveMonth
                                                              ) +
                                                              "/" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ].effectiveMonth
                                                              ) +
                                                              " 月 已上课时费用" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .underlineMonthSpendMoney
                                                              ) +
                                                              "元"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .underlineViewType ==
                                                                  1 ||
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .underlineViewType ==
                                                                  2,
                                                              expression:
                                                                "\n                            classInfo[tableIndex].underlineViewType == 1 ||\n                            classInfo[tableIndex].underlineViewType == 2\n                          "
                                                            }
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.classInfo[
                                                                tableIndex
                                                              ].signCount
                                                            ) +
                                                              "/" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ].lessonCount
                                                              ) +
                                                              " 课时 已上课时费用" +
                                                              _vm._s(
                                                                _vm.classInfo[
                                                                  tableIndex
                                                                ]
                                                                  .underlineSpendMoney
                                                              ) +
                                                              "元"
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _c("span", [_vm._v("- -")])
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.orderExtraInfo &&
                                      _vm.orderExtraInfo.goodsTemplate ===
                                        _vm.goodsTemplateEnum.cjzh
                                        ? _c("el-col", { attrs: { span: 5 } }, [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: { color: "#999" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.approvedRefundLabel
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("decimal2")(
                                                    _vm.classInfo[tableIndex]
                                                      .refundMoney
                                                  )
                                                ) + "元"
                                              )
                                            ])
                                          ])
                                        : _vm._e(),
                                      _vm.dropOutTableList.length > 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "expand",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleDropoutTable(
                                                    tableIndex
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.classInfo[tableIndex]
                                                    .expand
                                                    ? "详情"
                                                    : "收起"
                                                )
                                              ),
                                              _c("i", {
                                                class: [
                                                  !_vm.classInfo[tableIndex]
                                                    .expand
                                                    ? "el-icon-arrow-down"
                                                    : "el-icon-arrow-up"
                                                ]
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.classInfo[tableIndex].expand,
                                          expression:
                                            "classInfo[tableIndex].expand"
                                        }
                                      ]
                                    },
                                    [
                                      _c("div", { staticClass: "inout_bar" }, [
                                        _c("b", [_vm._v("收支项目")]),
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "el-icon-circle-plus color",
                                            on: {
                                              click: function($event) {
                                                return _vm.addDropOut(
                                                  tableIndex
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("添加扣费项目")]
                                        )
                                      ]),
                                      _c(
                                        "el-table",
                                        {
                                          staticClass: "dropout-table",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            border: "",
                                            stripe: "",
                                            size: "mini",
                                            "element-loading-text":
                                              "拼命加载中",
                                            "row-style": { height: "48px" },
                                            "cell-style": { padding: "0px" },
                                            "header-cell-style": {
                                              background: "#ffffff"
                                            },
                                            "header-cell-class-name":
                                              "normal-table-header",
                                            "show-summary": "",
                                            "summary-method":
                                              _vm.onCustomSummaryDropoutBox,
                                            data: dropOutTable
                                          },
                                          on: {
                                            "cell-click": _vm.onDropOutCellClick
                                          }
                                        },
                                        [
                                          _vm._l(_vm.dropOutColumns, function(
                                            item
                                          ) {
                                            return [
                                              item.prop === "receivableMoney"
                                                ? _c("el-table-column", {
                                                    key: item.prop,
                                                    attrs: {
                                                      "show-overflow-tooltip": true,
                                                      label: item.label,
                                                      prop: item.prop
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var row = ref.row
                                                            return [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    row.canDelete
                                                                      ? "--"
                                                                      : row.receivableMoney
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                : item.prop === "receivedMoney"
                                                ? _c("el-table-column", {
                                                    key: item.prop,
                                                    attrs: {
                                                      "show-overflow-tooltip": true,
                                                      label: item.label,
                                                      prop: item.prop
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var row = ref.row
                                                            return [
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    row.canDelete
                                                                      ? "--"
                                                                      : row.receivedMoney
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                : item.prop === "deductionMoney"
                                                ? _c(
                                                    "el-table-column",
                                                    {
                                                      key: item.prop,
                                                      attrs: {
                                                        "show-overflow-tooltip": true,
                                                        "class-name":
                                                          "deduction-column",
                                                        label: item.label,
                                                        prop: item.prop
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(
                                                              scope
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  attrs: {
                                                                    "data-type":
                                                                      "1"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "deduction-item",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.toggleDeductionMoney(
                                                                            scope.row,
                                                                            $event
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          attrs: {
                                                                            size:
                                                                              "mini",
                                                                            type:
                                                                              "number"
                                                                          },
                                                                          on: {
                                                                            blur: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.onDeductionMoneyBlur(
                                                                                dropOutTable,
                                                                                scope.row,
                                                                                scope
                                                                                  .row
                                                                                  .$index
                                                                              )
                                                                            },
                                                                            input: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.onDeductionMoneyInput(
                                                                                scope.row,
                                                                                scope
                                                                                  .row
                                                                                  .$index
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              scope
                                                                                .row
                                                                                .deductionMoney,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                "deductionMoney",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "scope.row.deductionMoney"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "header" },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.label
                                                                ) + " "
                                                              ),
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    effect:
                                                                      "light",
                                                                    content:
                                                                      "售后订单扣减的金额，扣减的金额不用于转班订单抵扣和退费",
                                                                    placement:
                                                                      "top-start"
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-question",
                                                                    staticStyle: {
                                                                      color:
                                                                        "#9BACBF",
                                                                      "font-size":
                                                                        "18px",
                                                                      "margin-left":
                                                                        "8px"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : item.prop ===
                                                  "refundableMoney"
                                                ? _c(
                                                    "el-table-column",
                                                    {
                                                      key: item.prop,
                                                      attrs: {
                                                        "show-overflow-tooltip": true,
                                                        label: item.label,
                                                        prop: item.prop
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var row = ref.row
                                                              return [
                                                                _c("p", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.refundableMoney
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "header" },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.label
                                                                ) + " "
                                                              ),
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    effect:
                                                                      "light",
                                                                    content:
                                                                      "售后订单扣减后剩余的金额，可用于转班抵扣，抵扣后剩余的金额可用于贷款平台退费和学员退费",
                                                                    placement:
                                                                      "top-start"
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-question",
                                                                    staticStyle: {
                                                                      color:
                                                                        "#9BACBF",
                                                                      "font-size":
                                                                        "18px",
                                                                      "margin-left":
                                                                        "8px"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : item.prop === "canDo"
                                                ? _c("el-table-column", {
                                                    key: item.prop,
                                                    attrs: {
                                                      "show-overflow-tooltip": true,
                                                      label: item.label,
                                                      prop: item.prop
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var row = ref.row
                                                            return [
                                                              row.canDelete
                                                                ? _c(
                                                                    "el-link",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "primary"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onDeleteDropOutTableRow(
                                                                            dropOutTable,
                                                                            tableIndex,
                                                                            row
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "删除"
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c("p", [
                                                                    _vm._v("--")
                                                                  ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                : _c("el-table-column", {
                                                    key: item.label,
                                                    attrs: {
                                                      "show-overflow-tooltip": true,
                                                      label: item.label,
                                                      width: item.width,
                                                      prop: item.prop
                                                    }
                                                  })
                                            ]
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("span", [_vm._v("退费结算")]),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "12px" } },
                    [
                      _c(
                        "el-col",
                        { staticStyle: { width: "280px" }, attrs: { span: 4 } },
                        [
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "fb14 color_title" }, [
                                _vm._v("合计扣费金额")
                              ]),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content:
                                      "售后订单扣减的金额总和，扣减的金额不用于转班订单抵扣和退费",
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#9BACBF",
                                      "margin-left": "12px"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "section",
                            { staticClass: "dealInfoBox fb14 color_price" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("decimal2")(_vm.allDeductionMoney)
                                ) + " 元"
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            width: "280px",
                            "margin-left": "24px"
                          },
                          attrs: { span: 4 }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "fb14 color_title" }, [
                                _vm._v(_vm._s(_vm.approvedRefundLabel))
                              ]),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content:
                                      "售后订单扣减后剩余的金额总和，可用于转班抵扣，抵扣后剩余的金额可用于贷款平台退费和学员退费",
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#9BACBF",
                                      "margin-left": "12px"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "section",
                            { staticClass: "dealInfoBox fb14 color_price" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("decimal2")(
                                    _vm.approvedRefundableAmount
                                  )
                                ) + " 元"
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                {
                  staticClass: "deal-info-wrap",
                  staticStyle: { "margin-top": "18px" }
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "sub-title",
                      staticStyle: { "margin-bottom": "20px" }
                    },
                    [_vm._v("成交信息")]
                  ),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "dealInfoList", attrs: { gutter: 24 } },
                      _vm._l(_vm.makeList, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item",
                            staticStyle: { "margin-bottom": "20px" },
                            attrs: { span: 4 }
                          },
                          [
                            item.value === "isFourSix"
                              ? _c("v-autocomplete", {
                                  attrs: {
                                    items: [
                                      { id: 1, name: "是" },
                                      { id: 0, name: "否" }
                                    ],
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true
                                    },
                                    label: "是否46分成",
                                    outlined: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    dense: true,
                                    height: 42,
                                    clearable: true,
                                    "clear-icon": "$clear",
                                    "hide-details": "auto",
                                    "no-data-text": "暂无数据"
                                  },
                                  model: {
                                    value: _vm.transactionInfo[item.value],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.transactionInfo,
                                        item.value,
                                        $$v
                                      )
                                    },
                                    expression: "transactionInfo[item.value]"
                                  }
                                })
                              : item.value === "lecturerAssigned"
                              ? _c("v-autocomplete", {
                                  staticStyle: { padding: "6px 0" },
                                  attrs: {
                                    items: [
                                      { id: true, name: "是" },
                                      { id: false, name: "否" }
                                    ],
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true
                                    },
                                    label: "是否外派",
                                    outlined: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    dense: true,
                                    height: 32,
                                    clearable: true,
                                    disabled: "",
                                    "clear-icon": "$clear",
                                    "hide-details": "auto",
                                    "no-data-text": "暂无数据"
                                  },
                                  model: {
                                    value: _vm.transactionInfo[item.value],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.transactionInfo,
                                        item.value,
                                        $$v
                                      )
                                    },
                                    expression: "transactionInfo[item.value]"
                                  }
                                })
                              : item.value === "promotionForm"
                              ? _c("v-autocomplete", {
                                  attrs: {
                                    items: [
                                      { id: "招生协助", name: "招生协助" },
                                      { id: "合伙人自主", name: "合伙人自主" }
                                    ],
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true
                                    },
                                    label: "促单形式",
                                    outlined: "",
                                    disabled: !_vm.transactionInfo[
                                      "economicFlowPartner"
                                    ],
                                    "item-text": "name",
                                    "item-value": "id",
                                    dense: true,
                                    height: 42,
                                    clearable: true,
                                    "clear-icon": "$clear",
                                    "hide-details": "auto",
                                    "no-data-text": "暂无数据"
                                  },
                                  model: {
                                    value: _vm.transactionInfo[item.value],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.transactionInfo,
                                        item.value,
                                        $$v
                                      )
                                    },
                                    expression: "transactionInfo[item.value]"
                                  }
                                })
                              : item.value === "transIntroduceType"
                              ? _c("v-text-field", {
                                  attrs: {
                                    label: item.label,
                                    "hide-details": "auto",
                                    disabled: "",
                                    outlined: "",
                                    dense: true,
                                    height: 42
                                  },
                                  model: {
                                    value:
                                      _vm.recommandTypeMap[
                                        _vm.transactionInfo[item.value]
                                      ],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.recommandTypeMap,
                                        _vm.transactionInfo[item.value],
                                        $$v
                                      )
                                    },
                                    expression:
                                      "recommandTypeMap[transactionInfo[item.value]]"
                                  }
                                })
                              : _c("v-text-field", {
                                  attrs: {
                                    label: item.label,
                                    "hide-details": "auto",
                                    disabled: "",
                                    outlined: "",
                                    dense: true,
                                    height: 42
                                  },
                                  model: {
                                    value: _vm.transactionInfo[item.value],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.transactionInfo,
                                        item.value,
                                        $$v
                                      )
                                    },
                                    expression: "transactionInfo[item.value]"
                                  }
                                })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                {
                  staticClass: "student-info-wrap",
                  staticStyle: { "padding-bottom": "12px" }
                },
                [
                  _c("h2", { staticClass: "sub-title" }, [
                    _vm._v("学员信息"),
                    _c("b", { staticClass: "tip" }, [
                      _vm._v(
                        "(学习账号用于app的学习与签约接收验证码，请正确填写！)"
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "16px" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { staticStyle: { width: "306px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline-formitem",
                                  class: {
                                    noAllowed: _vm.disabledChangeStuMobile
                                  },
                                  staticStyle: { "margin-bottom": "2px" },
                                  attrs: { prop: "stuMoblie" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "学习账号",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 42,
                                      disabled: _vm.isCanStudy
                                    },
                                    on: { blur: _vm.addCustomeruser },
                                    model: {
                                      value: _vm.signUpForm.stuMoblie,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "stuMoblie",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.stuMoblie"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { width: "306px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline-formitem",
                                  staticStyle: { "margin-bottom": "2px" },
                                  attrs: { prop: "name" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "学员姓名",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value: _vm.signUpForm.name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "name",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { width: "306px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline-formitem idCardBigBox",
                                  staticStyle: { "margin-bottom": "2px" },
                                  attrs: { prop: "idCard" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "身份证号",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    on: { blur: _vm.idcardBlur },
                                    model: {
                                      value: _vm.signUpForm.idCard,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "idCard",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.idCard"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { width: "306px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline-formitem",
                                  attrs: { prop: "education" }
                                },
                                [
                                  _c("dict-select", {
                                    attrs: { code: "EDUCATION_TYPE" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(props) {
                                          return _c("v-select", {
                                            attrs: {
                                              items: props.dictData,
                                              "menu-props": {
                                                bottom: true,
                                                offsetY: true
                                              },
                                              label: "学员学历",
                                              "item-text": "label",
                                              "item-value": "value",
                                              outlined: "",
                                              dense: true,
                                              height: 42,
                                              clearable: true,
                                              "clear-icon": "$clear",
                                              "hide-details": "auto"
                                            },
                                            model: {
                                              value: _vm.signUpForm.education,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.signUpForm,
                                                  "education",
                                                  $$v
                                                )
                                              },
                                              expression: "signUpForm.education"
                                            }
                                          })
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline-formitem idCardBigBox",
                                  attrs: { prop: "emergencyContact" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "紧急联系人",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value:
                                        _vm.transactionInfo.emergencyContact,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.transactionInfo,
                                          "emergencyContact",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "transactionInfo.emergencyContact"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline-formitem idCardBigBox",
                                  attrs: { prop: "emergencyContactPhone" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "紧急联系人电话",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value:
                                        _vm.transactionInfo
                                          .emergencyContactPhone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.transactionInfo,
                                          "emergencyContactPhone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "transactionInfo.emergencyContactPhone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "goods-info-wrap" }, [
                _c("h2", { staticClass: "sub-title" }, [_vm._v("商品信息")]),
                _c(
                  "section",
                  { staticClass: "goodsInfoBox" },
                  [
                    _c("div", { staticClass: "fieldRow" }, [
                      _c("div", { staticClass: "label" }, [_vm._v("报名类型")]),
                      _c(
                        "div",
                        { staticClass: "val" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: "转班报名",
                              disabled: "",
                              outlined: "",
                              dense: true,
                              height: 42,
                              "hide-details": "auto"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 12 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "goodsName" } },
                              [
                                _c("v-text-field", {
                                  staticStyle: {
                                    width: "100%",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    label: "选择商品",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: true,
                                    height: 42,
                                    readonly: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.onChoiceGoods.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.signUpForm.goodsName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.signUpForm, "goodsName", $$v)
                                    },
                                    expression: "signUpForm.goodsName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "line-height": "40px" },
                            attrs: { span: 8 }
                          },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.currentGoods.statusOnlineSell == 2,
                                    expression:
                                      "currentGoods.statusOnlineSell == 2"
                                  }
                                ],
                                staticStyle: { "margin-right": "12px" },
                                attrs: { type: "error" },
                                on: {
                                  click: function($event) {
                                    return _vm.previewGoods(
                                      _vm.currentGoods.goodsId
                                    )
                                  }
                                }
                              },
                              [_vm._v("预览")]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  type: "error",
                                  disabled: !_vm.signUpForm.goodsName
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeChoiceGoods(null)
                                  }
                                }
                              },
                              [_vm._v("重置")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    this.ctr.showZkSingleOption
                      ? [
                          _c("el-form-item", { attrs: { label: "报考专业" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.currentGoods.enrollingMajorName)
                              )
                            ])
                          ]),
                          _c(
                            "el-form-item",
                            [
                              _c("v-autocomplete", {
                                staticClass: "normal-select",
                                attrs: {
                                  items: this.zkSingle.enrollingProvinceList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  label: "报考院校",
                                  outlined: "",
                                  "item-text": "enrollingProvinceName",
                                  "item-value": "index",
                                  dense: true,
                                  height: 42,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  "no-data-text": "暂无数据"
                                },
                                on: { change: _vm.selectZkSingleProvince },
                                model: {
                                  value: _vm.zkSingle.form.provinceId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.zkSingle.form,
                                      "provinceId",
                                      $$v
                                    )
                                  },
                                  expression: "zkSingle.form.provinceId"
                                }
                              }),
                              _c("v-autocomplete", {
                                staticClass: "normal-select",
                                attrs: {
                                  items: this.zkSingle.enrollingSchoolList,
                                  "menu-props": { bottom: true, offsetY: true },
                                  outlined: "",
                                  "item-text": "enrollingSchoolName",
                                  "item-value": "index",
                                  dense: true,
                                  height: 42,
                                  clearable: true,
                                  "clear-icon": "$clear",
                                  "hide-details": "auto",
                                  "no-data-text": "暂无数据"
                                },
                                on: { change: _vm.selectZkSingleSchool },
                                model: {
                                  value: _vm.zkSingle.form.schoolId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.zkSingle.form, "schoolId", $$v)
                                  },
                                  expression: "zkSingle.form.schoolId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "specTable", label: "" } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    border: "",
                                    stripe: "",
                                    size: "mini",
                                    "element-loading-text": "拼命加载中",
                                    "row-style": { height: "48px" },
                                    "cell-style": { padding: "0px" },
                                    "header-cell-style": {
                                      background: "#e5f2ff"
                                    },
                                    "header-cell-class-name":
                                      "normal-table-header",
                                    data: _vm.zkSingle.zkGoodsSpecTable
                                  }
                                },
                                _vm._l(_vm.zkSingle.zkGoodsColumns, function(
                                  item
                                ) {
                                  return _c("el-table-column", {
                                    key: item.label,
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: item.label,
                                      prop: item.prop,
                                      width: item.width || "120px"
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "choiceClass",
                              attrs: {
                                prop: "choiceClass",
                                label: _vm.labelTitle
                              }
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "800px" },
                                  attrs: {
                                    border: "",
                                    stripe: "",
                                    size: "mini",
                                    "element-loading-text": "拼命加载中",
                                    "row-style": { height: "48px" },
                                    "cell-style": { padding: "0px" },
                                    "header-cell-style": {
                                      background: "#e5f2ff"
                                    },
                                    "header-cell-class-name":
                                      "normal-table-header",
                                    data: _vm.zkSingle.courseList
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "课程",
                                      prop: "courseName"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "类型",
                                      prop: "type"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "上课形式",
                                      prop: "classForm",
                                      width: "125"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    this.ctr.showCkSingleOption
                      ? [
                          _vm.showYxOption == true
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 8 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("schooleTree", {
                                        ref: "deptsys",
                                        attrs: { data: _vm.findSchoolList },
                                        on: {
                                          "update:select": _vm.selectSchoolNcid
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("br"),
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _vm.showYxOption == true
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "normal-select",
                                        attrs: {
                                          items: this.ckSingle
                                            .enrollingMajorList,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "报考专业",
                                          outlined: "",
                                          "item-text": "majorName",
                                          "item-value": "majorId",
                                          dense: true,
                                          height: 42,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto",
                                          "no-data-text": "暂无数据"
                                        },
                                        on: {
                                          change: _vm.selectCkSingleProfessional
                                        },
                                        model: {
                                          value:
                                            _vm.ckSingle.form.enrollingMajorId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ckSingle.form,
                                              "enrollingMajorId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ckSingle.form.enrollingMajorId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showYxOption == false
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          value:
                                            _vm.currentGoods
                                              .enrollingSchoolName,
                                          readonly: "",
                                          dense: "",
                                          outlined: "",
                                          height: 42,
                                          label: "报考院校"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showYxOption == false
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "normal-select",
                                        attrs: {
                                          items: this.ckSingle
                                            .enrollingMajorList,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "报考专业",
                                          outlined: "",
                                          "item-text": "majorName",
                                          "item-value": "id",
                                          dense: true,
                                          height: 42,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto",
                                          "no-data-text": "暂无数据"
                                        },
                                        on: {
                                          change: _vm.selectCkSingleProfessional
                                        },
                                        model: {
                                          value:
                                            _vm.ckSingle.form.enrollingMajorId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ckSingle.form,
                                              "enrollingMajorId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ckSingle.form.enrollingMajorId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "normal-select",
                                    attrs: {
                                      items: this.ckSingle
                                        .enrollingGradationList,
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "报考学级",
                                      outlined: "",
                                      "item-text": "gradationYear",
                                      "item-value": "id",
                                      dense: true,
                                      height: 42,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto",
                                      "no-data-text": "暂无数据"
                                    },
                                    on: { change: _vm.selectCkSingleGradation },
                                    model: {
                                      value:
                                        _vm.ckSingle.form.enrollingGradationId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ckSingle.form,
                                          "enrollingGradationId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ckSingle.form.enrollingGradationId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.repeat
                                ? _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("学员已报名该班型，请勿重复报名")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _vm.ckSingle.goodsPrice
                                    ? _c("v-text-field", {
                                        attrs: {
                                          value: _vm.ckSingle.goodsPrice + "元",
                                          readonly: "",
                                          dense: "",
                                          outlined: "",
                                          height: 42,
                                          label: "商品价格"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _vm.ckSingle.effectiveMonth
                                    ? _c("v-text-field", {
                                        attrs: {
                                          value:
                                            _vm.ckSingle.effectiveMonth +
                                            " 个月",
                                          readonly: "",
                                          dense: "",
                                          outlined: "",
                                          height: 42,
                                          label: "学习有效期"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.currentGoods.xlType != 3
                            ? [
                                (_vm.showCKGoods2
                                ? _vm.ckSingle.courseList.length
                                : true)
                                  ? _c(
                                      "el-form-item",
                                      {
                                        staticClass: "choiceClass",
                                        attrs: { prop: "choiceClass" }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(_vm.labelTitle))
                                        ]),
                                        _c(
                                          "el-table",
                                          {
                                            staticStyle: { width: "800px" },
                                            attrs: {
                                              border: "",
                                              stripe: "",
                                              size: "mini",
                                              "element-loading-text":
                                                "拼命加载中",
                                              "row-style": { height: "48px" },
                                              "cell-style": { padding: "0px" },
                                              "header-cell-style": {
                                                background: "#e5f2ff"
                                              },
                                              "header-cell-class-name":
                                                "normal-table-header",
                                              data: _vm.ckSingle.courseList
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "课程",
                                                prop: "courseName"
                                              }
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "类型",
                                                prop: "type"
                                              }
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "上课形式",
                                                prop: "classForm",
                                                width: "125"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm.showCKGoods2
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "goodsName" } },
                                [
                                  _vm.disabledsp
                                    ? _c(
                                        "el-row",
                                        {
                                          attrs: {
                                            gutter: 24,
                                            align: "middle",
                                            type: "flex"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c("v-text-field", {
                                                staticStyle: {
                                                  width: "100%",
                                                  display: "inline-block"
                                                },
                                                attrs: {
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  label: "第二志愿（选填）",
                                                  dense: true,
                                                  height: 42,
                                                  readonly: ""
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.onChoiceGoods(
                                                      _vm.SecondGoods
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.signUpForm.goodsName2,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.signUpForm,
                                                      "goodsName2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "signUpForm.goodsName2"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("el-col", { attrs: { span: 8 } }, [
                                            _c(
                                              "a",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.currentGoods2
                                                        .statusOnlineSell == 2,
                                                    expression:
                                                      "currentGoods2.statusOnlineSell == 2"
                                                  }
                                                ],
                                                staticStyle: {
                                                  "margin-left": "12px",
                                                  "margin-right": "12px"
                                                },
                                                attrs: { type: "error" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.previewGoods(
                                                      _vm.currentGoods2.goodsId
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("预览")]
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  type: "error",
                                                  disabled: !_vm.signUpForm
                                                    .goodsName2
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeChoiceGoods(
                                                      _vm.SecondGoods
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("重置")]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showYxOption2 == true
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("schooleTree", {
                                        ref: "deptsystwo",
                                        attrs: { data: _vm.findtwoSchoolList },
                                        on: {
                                          "update:select":
                                            _vm.selectSchoolNcidtwo
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("br"),
                          _vm.showCKGoods2
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _vm.showYxOption2 == false
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value:
                                                _vm.currentGoods2
                                                  .enrollingSchoolName,
                                              readonly: "",
                                              dense: "",
                                              outlined: "",
                                              height: 42,
                                              label: "报考院校"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _vm.showYxOption2 == true
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "enrollingMajorId2"
                                              }
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "normal-select",
                                                attrs: {
                                                  items:
                                                    _vm.ckSingle
                                                      .enrollingMajorList2,
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true
                                                  },
                                                  label: "报考专 业",
                                                  outlined: "",
                                                  "item-text": "majorName",
                                                  "item-value": "majorId",
                                                  dense: true,
                                                  height: 42,
                                                  clearable: true,
                                                  "clear-icon": "$clear",
                                                  "hide-details": "auto",
                                                  "no-data-text": "暂无数据"
                                                },
                                                on: {
                                                  change:
                                                    _vm.selectCkSingleProfessional2
                                                },
                                                model: {
                                                  value:
                                                    _vm.ckSingle.form
                                                      .enrollingMajorId2,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.ckSingle.form,
                                                      "enrollingMajorId2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ckSingle.form.enrollingMajorId2"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showYxOption2 == false
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "enrollingMajorId2"
                                              }
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "normal-select",
                                                attrs: {
                                                  items:
                                                    _vm.ckSingle
                                                      .enrollingMajorList2,
                                                  "menu-props": {
                                                    bottom: true,
                                                    offsetY: true
                                                  },
                                                  label: "报考 专业",
                                                  outlined: "",
                                                  "item-text": "majorName",
                                                  "item-value": "id",
                                                  dense: true,
                                                  height: 42,
                                                  clearable: true,
                                                  "clear-icon": "$clear",
                                                  "hide-details": "auto",
                                                  "no-data-text": "暂无数据"
                                                },
                                                on: {
                                                  change:
                                                    _vm.selectCkSingleProfessional2
                                                },
                                                model: {
                                                  value:
                                                    _vm.ckSingle.form
                                                      .enrollingMajorId2,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.ckSingle.form,
                                                      "enrollingMajorId2",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ckSingle.form.enrollingMajorId2"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      : _vm._e(),
                    this.ctr.showZkMultipleOption
                      ? [
                          _c(
                            "el-row",
                            { attrs: { gutter: 8 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "spec" } },
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          label: "选择班型",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 42,
                                          readonly: ""
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.onChoiceGoodsSpec.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.signUpForm.spec,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.signUpForm,
                                              "spec",
                                              $$v
                                            )
                                          },
                                          expression: "signUpForm.spec"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "报考专业" } },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "20px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.zkMultiple.curEnrollingMajorName)
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary plain" },
                                  on: { click: _vm.showTableOfDrawer }
                                },
                                [_vm._v("选择专业")]
                              )
                            ],
                            1
                          ),
                          _vm.zkMultiple.isDoubleProfessional
                            ? [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "xxxx" } },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "normal-select",
                                      attrs: {
                                        items: this.zkMultiple
                                          .zkEnrollingProvinceList,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "专科院校",
                                        outlined: "",
                                        "item-text": "enrollingProvinceName",
                                        "item-value": "index",
                                        dense: true,
                                        height: 42,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      on: {
                                        change: _vm.selectZkZkMultipleProvince
                                      },
                                      model: {
                                        value: _vm.zkMultiple.form.zkProvinceId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.zkMultiple.form,
                                            "zkProvinceId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "zkMultiple.form.zkProvinceId"
                                      }
                                    }),
                                    _c("v-autocomplete", {
                                      staticClass: "normal-select",
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: {
                                        items: this.zkMultiple
                                          .zkEnrollingSchoolList,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        outlined: "",
                                        "item-text": "enrollingSchoolName",
                                        "item-value": "index",
                                        dense: true,
                                        height: 42,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      on: {
                                        change: _vm.selectZkZkMultipleSchool
                                      },
                                      model: {
                                        value: _vm.zkMultiple.form.zkSchoolId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.zkMultiple.form,
                                            "zkSchoolId",
                                            $$v
                                          )
                                        },
                                        expression: "zkMultiple.form.zkSchoolId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "normal-select",
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: {
                                        items: this.zkMultiple
                                          .bkEnrollingProvinceList,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "本科院校",
                                        outlined: "",
                                        "item-text": "enrollingProvinceName",
                                        "item-value": "index",
                                        dense: true,
                                        height: 42,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      on: {
                                        change: _vm.selectZkBkMultipleProvince
                                      },
                                      model: {
                                        value: _vm.zkMultiple.form.bkProvinceId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.zkMultiple.form,
                                            "bkProvinceId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "zkMultiple.form.bkProvinceId"
                                      }
                                    }),
                                    _c("v-autocomplete", {
                                      staticClass: "normal-select",
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: {
                                        items: this.zkMultiple
                                          .bkEnrollingSchoolList,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        outlined: "",
                                        "item-text": "enrollingSchoolName",
                                        "item-value": "index",
                                        dense: true,
                                        height: 42,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      on: {
                                        change: _vm.selectZkBkMultipleSchool
                                      },
                                      model: {
                                        value: _vm.zkMultiple.form.bkSchoolId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.zkMultiple.form,
                                            "bkSchoolId",
                                            $$v
                                          )
                                        },
                                        expression: "zkMultiple.form.bkSchoolId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "normal-select",
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: {
                                        items: this.zkMultiple
                                          .enrollingProvinceList,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        label: "报考院校",
                                        outlined: "",
                                        "item-text": "enrollingProvinceName",
                                        "item-value": "index",
                                        dense: true,
                                        height: 42,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      on: {
                                        change: _vm.selectZkMultipleProvince
                                      },
                                      model: {
                                        value: _vm.zkMultiple.form.provinceId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.zkMultiple.form,
                                            "provinceId",
                                            $$v
                                          )
                                        },
                                        expression: "zkMultiple.form.provinceId"
                                      }
                                    }),
                                    _c("v-autocomplete", {
                                      staticClass: "normal-select",
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: {
                                        items: this.zkMultiple
                                          .enrollingSchoolList,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        outlined: "",
                                        "item-text": "enrollingSchoolName",
                                        "item-value": "index",
                                        dense: true,
                                        height: 42,
                                        clearable: true,
                                        "clear-icon": "$clear",
                                        "hide-details": "auto",
                                        "no-data-text": "暂无数据"
                                      },
                                      on: {
                                        change: _vm.selectZkMultipleSchool
                                      },
                                      model: {
                                        value: _vm.zkMultiple.form.schoolId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.zkMultiple.form,
                                            "schoolId",
                                            $$v
                                          )
                                        },
                                        expression: "zkMultiple.form.schoolId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                          _c(
                            "el-form-item",
                            { attrs: { prop: "specTable", label: "" } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    border: "",
                                    stripe: "",
                                    size: "mini",
                                    "element-loading-text": "拼命加载中",
                                    "row-style": { height: "48px" },
                                    "cell-style": { padding: "0px" },
                                    "header-cell-style": {
                                      background: "#e5f2ff"
                                    },
                                    "header-cell-class-name":
                                      "normal-table-header",
                                    data: _vm.zkMultiple.zkGoodsSpecTable
                                  }
                                },
                                _vm._l(_vm.zkMultiple.zkGoodsColumns, function(
                                  item
                                ) {
                                  return _c("el-table-column", {
                                    key: item.label,
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: item.label,
                                      prop: item.prop,
                                      width: item.width || "120px"
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "choiceClass",
                              attrs: { prop: "choiceClass" }
                            },
                            [
                              _c("span", { staticClass: "litter-title" }, [
                                _vm._v(_vm._s(_vm.labelTitle))
                              ]),
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    stripe: "",
                                    size: "mini",
                                    "element-loading-text": "拼命加载中",
                                    "row-style": { height: "48px" },
                                    "cell-style": { padding: "0px" },
                                    "header-cell-style": {
                                      background: "#e5f2ff"
                                    },
                                    "header-cell-class-name":
                                      "normal-table-header",
                                    data: _vm.zkMultiple.courseList
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "班型分组",
                                      prop: "classType",
                                      width: "150"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "班型",
                                      prop: "className"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "课程",
                                      prop: "courseName"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "类型",
                                      prop: "type",
                                      width: "125"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "show-overflow-tooltip": true,
                                      label: "上课形式",
                                      prop: "classForm",
                                      width: "125"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    this.ctr.showCjSingleOption
                      ? [
                          _c(
                            "el-row",
                            { attrs: { gutter: 8 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      style: {
                                        marginBottom: _vm.signUpForm.spec
                                          ? "10px"
                                          : "22px"
                                      },
                                      attrs: { prop: "spec" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          label: "选择班型",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 42,
                                          readonly: ""
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.onChoiceGoodsSpec.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.signUpForm.spec,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.signUpForm,
                                              "spec",
                                              $$v
                                            )
                                          },
                                          expression: "signUpForm.spec"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.repeat
                                ? _c(
                                    "el-col",
                                    {
                                      staticStyle: { "padding-bottom": "12px" },
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            "学员已报名该班型，请勿重复报名"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.employmentShow
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#333"
                                    }
                                  },
                                  [_vm._v("就业保底底金")]
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { margin: "12px 0" },
                                    attrs: { gutter: 8 }
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "jobLowestSalaryUnderCollege"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                label: "大专以下(元/月)",
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: true,
                                                height: 32
                                              },
                                              model: {
                                                value:
                                                  _vm.signUpForm
                                                    .jobLowestSalaryUnderCollege,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.signUpForm,
                                                    "jobLowestSalaryUnderCollege",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "signUpForm.jobLowestSalaryUnderCollege"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "jobLowestSalaryUpCollege"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                label: "大专及大专以上(元/月)",
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: true,
                                                height: 32
                                              },
                                              model: {
                                                value:
                                                  _vm.signUpForm
                                                    .jobLowestSalaryUpCollege,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.signUpForm,
                                                    "jobLowestSalaryUpCollege",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "signUpForm.jobLowestSalaryUpCollege"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm.currentGoods.goodsTemplate != 6
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "specTable" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        border: "",
                                        stripe: "",
                                        size: "mini",
                                        "element-loading-text": "拼命加载中",
                                        "row-style": { height: "48px" },
                                        "cell-style": { padding: "0px" },
                                        "header-cell-style": {
                                          background: "#e5f2ff"
                                        },
                                        "highlight-current-row": "",
                                        "header-cell-class-name":
                                          "normal-table-header",
                                        data: _vm.goodsSpecTable
                                      }
                                    },
                                    [
                                      _vm._l(_vm.goodsColumns, function(item) {
                                        return [
                                          item.prop === "goodsDiscountPrice"
                                            ? _c("el-table-column", {
                                                key: item.prop,
                                                attrs: {
                                                  "show-overflow-tooltip": true,
                                                  prop: item.prop,
                                                  label: item.label
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var row = ref.row
                                                        return [
                                                          row.goodsDiscountPrice !=
                                                            "" &&
                                                          row.goodsDiscountPrice !=
                                                            "--" &&
                                                          row.goodsDiscountPrice !=
                                                            undefined
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      (
                                                                        row.goodsPrice -
                                                                        row.goodsDiscountPrice
                                                                      ).toFixed(
                                                                        2
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("span", [
                                                                _vm._v("- -")
                                                              ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            : _c("el-table-column", {
                                                key: item.label,
                                                attrs: {
                                                  "show-overflow-tooltip": true,
                                                  width: item.width || "120px",
                                                  label: item.label,
                                                  prop: item.prop
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var row = ref.row
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f("empty")(
                                                                  row[
                                                                    item.prop
                                                                  ],
                                                                  "--"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentGoods.goodsTemplate == 6
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "specTable" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { color: "#666" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "商品价格: ¥" +
                                            _vm._s(
                                              (_vm.goodsSpecTable[0] &&
                                                _vm.goodsSpecTable[0]
                                                  .goodsPrice) ||
                                                0.0
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.currentGoods.goodsTemplate == 6
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "specTable" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        border: "",
                                        stripe: "",
                                        size: "mini",
                                        "element-loading-text": "拼命加载中",
                                        "row-style": { height: "48px" },
                                        "cell-style": { padding: "0px" },
                                        "header-cell-style": {
                                          background: "#e5f2ff"
                                        },
                                        "highlight-current-row": "",
                                        "header-cell-class-name":
                                          "normal-table-header",
                                        data: _vm.comComGoodsInfoList
                                      }
                                    },
                                    [
                                      _vm._l(_vm.cjComComGoodsColumns, function(
                                        item
                                      ) {
                                        return [
                                          _c("el-table-column", {
                                            key: item.label,
                                            attrs: {
                                              "show-overflow-tooltip": true,
                                              width: item.width || "120px",
                                              label: item.label,
                                              prop: item.prop
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("empty")(
                                                              row[item.prop],
                                                              "--"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "choiceClass",
                              attrs: { prop: "choiceClass" }
                            },
                            [
                              _c("span", { staticClass: "litter-title" }, [
                                _vm._v(_vm._s(_vm.labelTitle))
                              ]),
                              _c("selcetionClass", {
                                ref: "selcetionClass",
                                attrs: {
                                  mealGroupData: _vm.mealGroupData,
                                  type: _vm.currentGoods.type,
                                  oneItem: _vm.oneItem
                                },
                                on: { confirmClass: _vm.confirmClass }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "colllection-info-wrap" }, [
                _c("h2", { staticClass: "sub-title" }, [_vm._v("收款信息")]),
                _c(
                  "div",
                  { staticClass: "collectionInfoList", attrs: { gutter: 24 } },
                  [
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            value:
                              (_vm.isCj
                                ? (+_vm.newGoodsPrice).toFixed(2)
                                : (+_vm.zkNewGoodsPrice).toFixed(2)) + "元",
                            label: "商品价格",
                            "hide-details": "auto",
                            outlined: "",
                            dense: true,
                            height: 42,
                            readonly: ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        staticStyle: {
                          display: "inline-block",
                          "margin-bottom": "10px"
                        }
                      },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.discountInfoList,
                            "menu-props": { bottom: true, offsetY: true },
                            label: "商品优惠",
                            outlined: "",
                            "item-text": "activityName",
                            "item-value": "id",
                            dense: true,
                            height: 42,
                            clearable: true,
                            "clear-icon": "$clear",
                            "hide-details": "auto",
                            "no-data-text": "暂无数据"
                          },
                          on: { change: _vm.selectDiscountInfo },
                          model: {
                            value: _vm.discountType,
                            callback: function($$v) {
                              _vm.discountType = $$v
                            },
                            expression: "discountType"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        staticStyle: {
                          display: "inline-block",
                          "margin-bottom": "10px"
                        }
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            value: _vm.discountPrice,
                            label: "商品优惠金额",
                            "hide-details": "auto",
                            outlined: "",
                            dense: true,
                            height: 42,
                            disabled: !_vm.wxTradeOrderDiscountVOList.length
                          },
                          on: { input: _vm.inputDiscountPrice }
                        })
                      ],
                      1
                    ),
                    _vm.discountNotuse.length
                      ? _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "line-height": "32px",
                                  fontsize: "14px",
                                  color: "#f00",
                                  cursor: "pointer"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.disountsShow = true
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline"
                                }),
                                _vm._v("不可用优惠 ")
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.wxTradeOrderDiscountVOList.length
                      ? _c("div", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                fontSize: "14px",
                                color: "#f00"
                              }
                            },
                            [_vm._v(_vm._s(_vm.discountTip))]
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            value: _vm.amountReceivable + "元",
                            label: "应收金额",
                            "hide-details": "auto",
                            outlined: "",
                            dense: true,
                            height: 42,
                            readonly: ""
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "colllection-info-wrap" },
                [
                  _c("h2", { staticClass: "colllection-info-title" }, [
                    _vm._v("收款项目")
                  ]),
                  _c("receiveItem", {
                    attrs: {
                      columns: _vm.receiveItemColumns,
                      tableData: _vm.receiveItemDataList,
                      showSummary: true,
                      summaryMethod: _vm.receiveItemSummaryMethod
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "payinfo-wrap" },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "sub-title",
                      staticStyle: { margin: "18px 0 6px 0" }
                    },
                    [_vm._v("付款信息")]
                  ),
                  _c("span", [_vm._v("转班结算")]),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "12px" } },
                    [
                      _c(
                        "el-col",
                        { staticStyle: { width: "280px" }, attrs: { span: 4 } },
                        [
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "fb14 color_title" }, [
                                _vm._v("转班抵扣")
                              ]),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content:
                                      "售后订单抵扣到转班订单的金额，用于转班订单付款",
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#9BACBF",
                                      "margin-left": "12px"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "section",
                            { staticClass: "dealInfoBox fb14 color_price" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("decimal2")(
                                    _vm.approvedRefundableAmount
                                  )
                                ) + " 元"
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            width: "280px",
                            "margin-left": "24px"
                          },
                          attrs: { span: 4 }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("span", { staticClass: "fb14 color_title" }, [
                                _vm._v("应退金额")
                              ]),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content:
                                      "售后订单抵扣后剩余的金额，可用于贷款平台退费和学员退费",
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#9BACBF",
                                      "margin-left": "12px"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "section",
                            { staticClass: "dealInfoBox fb14 color_price" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("decimal2")(_vm.shouldReturnPayPrice)
                                ) + " 元"
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.shouldReturnPayPrice > 0
                ? _c(
                    "div",
                    { staticClass: "payinfo-wrap" },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("span", [
                            _vm._v("贷款退费"),
                            _c("span", { staticClass: "start" }, [_vm._v("*")])
                          ]),
                          _c("check-box", {
                            ref: "checkLoan",
                            staticStyle: { "margin-left": "-10px" },
                            attrs: {
                              list: [
                                { label: "是", value: 1 },
                                { label: "否", value: 0 }
                              ],
                              select: _vm.signUpForm.isLoanRefund,
                              disabled: [
                                _vm.installmentModeEnum.onlineLoan,
                                _vm.installmentModeEnum.offlineLoan
                              ].includes(_vm.installmentMode)
                            },
                            on: {
                              "update:select": [
                                function($event) {
                                  return _vm.$set(
                                    _vm.signUpForm,
                                    "isLoanRefund",
                                    $event
                                  )
                                },
                                _vm.switchOneOrMore
                              ]
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "colllection-info-wrap loan-info-comp" },
                [
                  _vm.signUpForm.isLoanRefund && _vm.shouldReturnPayPrice > 0
                    ? _c("loan-info", {
                        attrs: {
                          loanInfoForm: _vm.dropoutForm,
                          rulesForm: _vm.signUpForm,
                          installmentMode: _vm.installmentMode,
                          loanRefundStuMoney: _vm.loanRefundStuMoney,
                          loanIouNoDisabled: _vm.loanIouNoDisabled,
                          rules: _vm.formRules,
                          orderTable: _vm.orderTable,
                          loanPlatformList: _vm.loanPlatformList,
                          loanRefundMoneyVerify: _vm.loanRefundMoneyVerify
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "payinfo-wrap" },
                [
                  _c("span", [_vm._v("学员退费")]),
                  _c("el-row", { staticStyle: { "margin-top": "12px" } }, [
                    _c("div", { staticClass: "fb14 color_title" }, [
                      _vm._v("退费金额")
                    ]),
                    _c(
                      "section",
                      {
                        staticClass: "dealInfoBox fb14 color_price",
                        staticStyle: {
                          "margin-right": "20px",
                          display: "inline-block"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("decimal2")(_vm.loanRefundStuMoney)) +
                            " 元"
                        )
                      ]
                    ),
                    _vm.loanRefundStuMoney < 0
                      ? _c(
                          "span",
                          {
                            staticClass: "approvedRefundTxt",
                            staticStyle: { color: "red" }
                          },
                          [
                            _c("i", { staticClass: "el-icon-warning" }),
                            _vm._v("原订单需要学员补款，暂不支持售后申请")
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm.loanRefundStuMoney !== 0
                    ? _c(
                        "el-form-item",
                        { staticClass: "border", attrs: { prop: "payMethod" } },
                        [
                          _c("span", [
                            _vm._v("付款方式"),
                            _c("span", { staticClass: "start" }, [_vm._v("*")])
                          ]),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "light",
                                content:
                                  "售后订单扣减后剩余的金额，可用于贷款平台退费和学员退费",
                                placement: "top-start"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "1、学员余额，售后订单退费至学员学习账号，下次报名时可使用余额付款"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 2、银行账户，售后订单退费至学员银行账户，下次报名时需学员重新付款"
                                  )
                                ]
                              ),
                              _c("i", {
                                staticClass: "el-icon-question",
                                staticStyle: {
                                  color: "#9BACBF",
                                  "font-size": "18px",
                                  "margin-left": "8px"
                                }
                              })
                            ]
                          ),
                          _c("check-box", {
                            ref: "check",
                            staticStyle: {
                              "margin-left": "-10px",
                              "margin-top": "10px"
                            },
                            attrs: {
                              list: _vm.refundList,
                              select: _vm.dropoutForm.payType
                            },
                            on: {
                              "update:select": [
                                function($event) {
                                  return _vm.$set(
                                    _vm.dropoutForm,
                                    "payType",
                                    $event
                                  )
                                },
                                _vm.dropoutFormtype
                              ]
                            }
                          }),
                          _vm.dropoutForm.payType == "0"
                            ? _c("span", { staticStyle: { color: "#f00" } }, [
                                _vm._v(
                                  "学员余额仅用于一转多的场景，该学员的余额仅限于当前学员使用，不支持转给其他学员所使用"
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.dropoutForm.payType == "0",
                                  expression: "dropoutForm.payType == '0'"
                                }
                              ],
                              staticClass: "radio1-content",
                              staticStyle: { "margin-top": "10px" }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-right": "24px",
                                    width: "282px"
                                  },
                                  attrs: { prop: "dropOrderNo" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      label: "学员账号",
                                      disabled: "",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value: _vm.signUpForm.dropMobile,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "dropMobile",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.dropMobile"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "282px"
                                  },
                                  attrs: { prop: "dropName" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      label: "学员姓名",
                                      disabled: "",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value: _vm.signUpForm.dropName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "dropName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.dropName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.dropoutForm.payType == "1",
                                  expression: "dropoutForm.payType == '1'"
                                }
                              ],
                              staticClass: "radio2-content",
                              staticStyle: { "margin-top": "10px" }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    "margin-right": "24px",
                                    display: "inline-block",
                                    width: "282px",
                                    "margin-bottom": "12px"
                                  },
                                  attrs: { prop: "bankAccountName" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      placeholder: "学员开户名",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value: _vm.signUpForm.bankAccountName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "bankAccountName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.bankAccountName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-right": "24px",
                                    width: "282px"
                                  },
                                  attrs: { prop: "bank" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      placeholder: "学员开户行及网点",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value: _vm.signUpForm.bank,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "bank",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.bank"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "282px"
                                  },
                                  attrs: { prop: "bankAccount" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      placeholder: "学员户行账号",
                                      outlined: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value: _vm.signUpForm.bankAccount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "bankAccount",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "signUpForm.bankAccount"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "588px"
                                      },
                                      attrs: { prop: "ncBankAccountPk" }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.payBankList,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          "item-text": "accountName",
                                          "item-value": "bankAccountPk",
                                          placeholder: "付款银行",
                                          outlined: "",
                                          dense: true,
                                          height: 42,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto"
                                        },
                                        on: {
                                          change: _vm.onBankAccountPkChange
                                        },
                                        model: {
                                          value: _vm.signUpForm.ncBankAccountPk,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.signUpForm,
                                              "ncBankAccountPk",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "signUpForm.ncBankAccountPk"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.dropoutForm.payType == 2,
                                  expression: "dropoutForm.payType == 2"
                                }
                              ],
                              staticStyle: { "margin-top": "12px" }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-right": "24px",
                                    width: "282px"
                                  },
                                  attrs: { prop: "ohterName" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "auto",
                                      label: "第三方",
                                      outlined: "",
                                      disabled: "",
                                      dense: true,
                                      height: 42
                                    },
                                    model: {
                                      value: _vm.signUpForm.ohterName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.signUpForm,
                                          "ohterName",
                                          $$v
                                        )
                                      },
                                      expression: "signUpForm.ohterName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom-btn" },
        [
          _c(
            "el-button",
            { attrs: { size: "middle", gap: 20 }, on: { click: _vm.doBack } },
            [_vm._v("返回")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.currentGoods.goodsId,
                type: "primary",
                size: "middle",
                loading: _vm.submitLoading
              },
              on: { click: _vm.showSureData }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("order-modal", {
        ref: "orderModal",
        attrs: {
          visible: _vm.orderModalVisible,
          aftersaleType: 2,
          orderModalFields: _vm.orderModalFields,
          orderModalItems: _vm.orderModalItems,
          orderModalRules: _vm.orderModalRules,
          selectMode: _vm.signUpForm.oneOrMore
        },
        on: { "on-cancel": _vm.onOrderModalCancel, "on-ok": _vm.onOrderModalOk }
      }),
      _vm.flag
        ? _c("goods-specs-modal", {
            key: _vm.goodsSpecModalKey,
            attrs: {
              visible: _vm.gsVisible,
              specTypeArr: _vm.specTypeArr,
              specArray: _vm.specArray,
              specCountArr: _vm.specCountArr,
              specPriceArr: _vm.specPriceArr,
              currentGoodsRank: _vm.currentGoodsRank
            },
            on: {
              "on-cancel": _vm.onGsCancel,
              onSpecModalOk: _vm.onSpecModalOk
            }
          })
        : _vm._e(),
      _c("goods-modal", {
        ref: "goodsModalRef",
        attrs: {
          visible: _vm.goodsDrawerVisible,
          goodsForm: _vm.goodsForm,
          goodsFormRules: _vm.goodsFormRules,
          goodsFormItems: _vm.goodsFormItems,
          businessId: "",
          selectGoodsType: _vm.selectGoodsType,
          enrollingMajorId: _vm.firstEnrollingMajorId,
          enrollingGradation: _vm.ckSingle.enrollingTime,
          enrollingProductId: _vm.currentGoods.productId,
          type: _vm.goodModalTypeEnum.transfer,
          schoolId: _vm.ncDealSchoolId
        },
        on: {
          "update:visible": function($event) {
            _vm.goodsDrawerVisible = $event
          },
          "on-ok": _vm.onGoodsDrawerOk
        }
      }),
      _c("receiveOut", {
        attrs: {
          visible: _vm.receiveOutVisible,
          aftersaleType: 2,
          orderModalFields: _vm.receiveOutFields,
          orderModalItems: _vm.receiveOut,
          orderModalRules: _vm.orderModalRules,
          dropOutTableList: _vm.dropOutTableList,
          currentSelDrop: _vm.currentSelDrop,
          selectMode: "1"
        },
        on: { "on-cancel": _vm.onReceiveOutCancel, "on-ok": _vm.onReceiveOutOk }
      }),
      _c("sure", {
        attrs: {
          visible: _vm.sureVisible,
          showTitle: "转班报名确认",
          sureData: _vm.sureData,
          loading: _vm.loading
        },
        on: { "on-cancel": _vm.onSureCancel, "on-ok": _vm.onSureOk }
      }),
      _c("selUser", {
        attrs: {
          visible: _vm.selUserVisible,
          aftersaleType: 2,
          orderModalFields: _vm.selUserFields,
          orderModalItems: _vm.selUser,
          orderModalRules: _vm.selUserRules,
          selectMode: "1"
        },
        on: { "on-cancel": _vm.onSelUserCancel, "on-ok": _vm.onSelUserOk }
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.showDelOrder, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.showDelOrder = $event
            }
          }
        },
        [
          _c("span", [
            _vm._v("删除该售后订单将清空已选转班商品信息，确认是否删除订单")
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDelOrder = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sureDelOrder } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "选择专业",
            visible: _vm.showTable,
            direction: "rtl",
            size: "70%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showTable = $event
            }
          }
        },
        [
          _c("div", { staticClass: "meunBox" }, [
            _c(
              "section",
              [
                _vm._l(_vm.zkMultiple.mealGroupData, function(
                  mealGroupItem,
                  mealGroupIndex
                ) {
                  return _c(
                    "div",
                    { key: mealGroupIndex },
                    _vm._l(mealGroupItem.goodsGroupList, function(
                      goodsGroupItem,
                      goodsGroupIndex
                    ) {
                      return _c(
                        "div",
                        { key: goodsGroupIndex, staticClass: "goodsSelBox" },
                        [
                          _c("div", { staticClass: "header-part" }, [
                            _c("div", [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(_vm._s(goodsGroupItem.name))
                              ]),
                              _c("span", { staticClass: "intro" }, [
                                _vm._v(
                                  " (共" +
                                    _vm._s(goodsGroupItem.goods.length) +
                                    "门，任选 1 门)"
                                )
                              ])
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "selNum" }, [
                                _vm._v("已选 ")
                              ]),
                              _c("span", { staticClass: "nowSel" }, [
                                _vm._v(
                                  _vm._s(goodsGroupItem.selected_count || 0)
                                )
                              ]),
                              _vm._v("/"),
                              _c("span", { staticClass: "canSel" }, [
                                _vm._v("1")
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "classBox" }, [
                            _c(
                              "ul",
                              _vm._l(goodsGroupItem.goods, function(
                                goodsItem,
                                goodsIndex
                              ) {
                                return _c(
                                  "li",
                                  {
                                    key: goodsIndex,
                                    class: goodsItem.isSelected
                                      ? "selected"
                                      : "",
                                    on: {
                                      click: function($event) {
                                        return _vm.selectGoods(
                                          mealGroupIndex,
                                          goodsGroupIndex,
                                          goodsIndex
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("el-checkbox", {
                                      model: {
                                        value: goodsItem.isSelected,
                                        callback: function($$v) {
                                          _vm.$set(goodsItem, "isSelected", $$v)
                                        },
                                        expression: "goodsItem.isSelected"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-left": "12px" }
                                      },
                                      [_vm._v(_vm._s(goodsItem.name))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "btns",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "large", type: "primary" },
                        on: { click: _vm.professionalAlertEnsure }
                      },
                      [_vm._v("确定")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "large" },
                        on: { click: _vm.professionalAlertCancel }
                      },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ],
              2
            )
          ])
        ]
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.disountsShow,
            title: "不可用优惠",
            width: "480"
          },
          on: {
            "on-cancel": function($event) {
              _vm.disountsShow = false
            }
          }
        },
        [
          _vm._l(_vm.discountNotuse, function(item) {
            return _c("div", { key: item.id, staticClass: "discount-modal" }, [
              _c("div", [_vm._v(_vm._s(item.activityName))]),
              _c("div", [
                _vm._v("优惠¥0.00~ ¥" + _vm._s(item.preferentialAmount) + ".00")
              ]),
              _c("div", [
                _c("div", [_vm._v("学员不符合优惠条件")]),
                _c("div", [_vm._v(_vm._s(item.endTime) + " 过期")])
              ])
            ])
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.disountsShow = false
                    }
                  }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("discount", { ref: "discount", on: { saveDicount: _vm.saveDicount } }),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.showTis, title: "提交失败", width: "480" },
          on: { "on-cancel": _vm.tijiaoProcancel }
        },
        [
          _c("div", [
            _c("i", { attrs: { icon: "el-icon-warning", type: "danger" } }),
            _vm._v("失败原因")
          ]),
          _c(
            "div",
            { staticClass: "tishiclass" },
            _vm._l(_vm.tiforList, function(item, index) {
              return _c("ul", { key: index }, [
                _c("li", [_vm._v(_vm._s(item))])
              ])
            }),
            0
          ),
          _c("v-textarea", {
            attrs: {
              "hide-details": "auto",
              label: "开通零元课程原因",
              placeholder: "请输入开通零元课程原因,500字以内",
              outlined: "",
              dense: true,
              height: 120,
              maxlength: "500",
              counter: ""
            },
            model: {
              value: _vm.lingyuanForm.lingyuan,
              callback: function($$v) {
                _vm.$set(
                  _vm.lingyuanForm,
                  "lingyuan",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "lingyuanForm.lingyuan"
            }
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.tijiaoProcancel }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.tijiaoProadd }
                },
                [_vm._v("提交审批")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }